import _ from "underscore";
import {saveSearchAdsData, batchSaveAdsData, generateSearchAdsData} from "@/utils/apis";

export default {
    name: "CreateSearchAds",
    data() {
        let onlyNumber = (rule, value, callback) => {
            if (!Number.isInteger(value)) {
                callback(new Error("分值必须为正整数"));
            } else {
                if (Number(value) > 100 || Number(value) < 1) {
                    callback(new Error('分值要大于1且小于等于100'));
                } else {
                    callback();
                }
            }
        };
        let onlyInt = (rule, value, callback) => {
            if (!Number.isInteger(value)) {
                callback(new Error('余额必须为正整数'));
            } else {
                if (Number(value) === 0) {
                    callback(new Error('余额不能为零或为空'));
                } else {
                    callback();
                }
            }
        };
        return {
            stepActive: 1,
            isSetDataOne: false,
            isSetDataTwo: false,
            createMessage: '创建成功',
            total: 0,
            process_index: 0,
            //搜索广告表单数据
            searchAdForm: {
                name: '',
                category_id: '',
                gid_id: '',
                score: '',
                sold_score: "",
                conversion_rate_score: "",
                balance: '',
                remark: '',
                background: "",
                requirement: "",
                //时段设置
                time_data: {
                    time1_potential_client: 0, //0~6时潜在客户数
                    time1_potential_client_wave1: 0, //0~6时潜在客户数波动返回 #号隔开
                    time1_potential_client_wave2: 0,
                    time2_potential_client: 0, //6~12
                    time2_potential_client_wave1: 0,
                    time2_potential_client_wave2: 0,
                    time3_potential_client: 0, //12~18
                    time3_potential_client_wave1: 0,
                    time3_potential_client_wave2: 0,
                    time4_potential_client: 0, //18~24
                    time4_potential_client_wave1: 0,
                    time4_potential_client_wave2: 0,
                    time1_click_rate: null, //点击率
                    time1_click_rate_wave1: 0,
                    time1_click_rate_wave2: 0,
                    time2_click_rate: null,
                    time2_click_rate_wave1: 0,
                    time2_click_rate_wave2: 0,
                    time3_click_rate: null,
                    time3_click_rate_wave1: 0,
                    time3_click_rate_wave2: 0,
                    time4_click_rate: null,
                    time4_click_rate_wave1: 0,
                    time4_click_rate_wave2: 0,
                    time1_tran_rate: null, //转化率
                    time1_tran_rate_wave1: 0,
                    time1_tran_rate_wave2: 0,
                    time2_tran_rate: null,
                    time2_tran_rate_wave1: 0,
                    time2_tran_rate_wave2: 0,
                    time3_tran_rate: null,
                    time3_tran_rate_wave1: 0,
                    time3_tran_rate_wave2: 0,
                    time4_tran_rate: null,
                    time4_tran_rate_wave1: 0,
                    time4_tran_rate_wave2: 0,
                },
                //地区设置
                area_data: {
                    north_potential_client: null, //华北 潜在客户数
                    north_potential_client_wave1: 0, //波动范围
                    north_potential_client_wave2: 0, //波动范围
                    northeast_potential_client: null, //东北
                    northeast_potential_client_wave1: 0,
                    northeast_potential_client_wave2: 0,
                    east_potential_client: null, //华东
                    east_potential_client_wave1: 0,
                    east_potential_client_wave2: 0,
                    central_potential_client: null, //华中
                    central_potential_client_wave1: 0,
                    central_potential_client_wave2: 0,
                    south_potential_client: null, //华南
                    south_potential_client_wave1: 0,
                    south_potential_client_wave2: 0,
                    southwest_potential_client: null, //西南
                    southwest_potential_client_wave1: 0,
                    southwest_potential_client_wave2: 0,
                    northwest_potential_client: null, //西北
                    northwest_potential_client_wave1: 0,
                    northwest_potential_client_wave2: 0,
                    north_click_rate: null, //点击率
                    north_click_rate_wave1: 0,
                    north_click_rate_wave2: 0,
                    northeast_click_rate: null,
                    northeast_click_rate_wave1: 0,
                    northeast_click_rate_wave2: 0,
                    east_click_rate: null,
                    east_click_rate_wave1: 0,
                    east_click_rate_wave2: 0,
                    central_click_rate: null,
                    central_click_rate_wave1: 0,
                    central_click_rate_wave2: 0,
                    south_click_rate: null,
                    south_click_rate_wave1: 0,
                    south_click_rate_wave2: 0,
                    southwest_click_rate: null,
                    southwest_click_rate_wave1: 0,
                    southwest_click_rate_wave2: 0,
                    northwest_click_rate: null,
                    northwest_click_rate_wave1: 0,
                    northwest_click_rate_wave2: 0,
                    north_tran_rate: null, //转化率
                    north_tran_rate_wave1: 0,
                    north_tran_rate_wave2: 0,
                    northeast_tran_rate: null,
                    northeast_tran_rate_wave1: 0,
                    northeast_tran_rate_wave2: 0,
                    east_tran_rate: null,
                    east_tran_rate_wave1: 0,
                    east_tran_rate_wave2: 0,
                    central_tran_rate: null,
                    central_tran_rate_wave1: 0,
                    central_tran_rate_wave2: 0,
                    south_tran_rate: null,
                    south_tran_rate_wave1: 0,
                    south_tran_rate_wave2: 0,
                    southwest_tran_rate: null,
                    southwest_tran_rate_wave1: 0,
                    southwest_tran_rate_wave2: 0,
                    northwest_tran_rate: null,
                    northwest_tran_rate_wave1: 0,
                    northwest_tran_rate_wave2: 0,
                },
                //人群定向
                people_data: {
                    kernel_potential_client: null, //核心人群 潜在客户数
                    perceive_potential_client: null, //认知人群
                    intention_potential_client: null, //意向人群
                    products_potential_client: null, //竞品人群
                    store_potential_client: null, //店铺人群
                    category_potential_client: null, //类目相关人群
                    kernel_click_rate: null, //点击率
                    perceive_click_rate: null,
                    intention_click_rate: null,
                    products_click_rate: null,
                    store_click_rate: null,
                    category_click_rate: null,
                    kernel_tran_rate: null, // 转化率
                    perceive_tran_rate: null,
                    intention_tran_rate: null,
                    products_tran_rate: null,
                    store_tran_rate: null,
                    category_tran_rate: null
                },
                time_set: {
                    potential_client: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                    click_rate: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                    tran_rate: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
                },
                area_set: {
                    potential_client: [
                        { name: '北京市', num: null },
                        { name: '天津市', num: null },
                        { name: '河北省', num: null },
                        { name: '山西省', num: null },
                        { name: '内蒙古自治区', num: null },
                        { name: '辽宁省', num: null },
                        { name: '吉林省', num: null },
                        { name: '黑龙江省', num: null },
                        { name: '上海市', num: null },
                        { name: '江苏省', num: null },
                        { name: '浙江省', num: null },
                        { name: '福建省', num: null },
                        { name: '安徽省', num: null },
                        { name: '山东省', num: null },
                        { name: '河南省', num: null },
                        { name: '河北省', num: null },
                        { name: '湖南省', num: null },
                        { name: '江西省', num: null },
                        { name: '广东省', num: null },
                        { name: '海南省', num: null },
                        { name: '广西壮族自治区', num: null },
                        { name: '重庆市', num: null },
                        { name: '四川省', num: null },
                        { name: '云南省', num: null },
                        { name: '贵州省', num: null },
                        { name: '西藏自治区', num: null },
                        { name: '陕西省', num: null },
                        { name: '甘肃省', num: null },
                        { name: '青海省', num: null },
                        { name: '宁夏回族自治区', num: null },
                        { name: '新疆维吾尔自治区', num: null }
                    ],
                    click_rate: [
                        { name: '北京市', num: null },
                        { name: '天津市', num: null },
                        { name: '河北省', num: null },
                        { name: '山西省', num: null },
                        { name: '内蒙古自治区', num: null },
                        { name: '辽宁省', num: null },
                        { name: '吉林省', num: null },
                        { name: '黑龙江省', num: null },
                        { name: '上海市', num: null },
                        { name: '江苏省', num: null },
                        { name: '浙江省', num: null },
                        { name: '福建省', num: null },
                        { name: '安徽省', num: null },
                        { name: '山东省', num: null },
                        { name: '河南省', num: null },
                        { name: '河北省', num: null },
                        { name: '湖南省', num: null },
                        { name: '江西省', num: null },
                        { name: '广东省', num: null },
                        { name: '海南省', num: null },
                        { name: '广西壮族自治区', num: null },
                        { name: '重庆市', num: null },
                        { name: '四川省', num: null },
                        { name: '云南省', num: null },
                        { name: '贵州省', num: null },
                        { name: '西藏自治区', num: null },
                        { name: '陕西省', num: null },
                        { name: '甘肃省', num: null },
                        { name: '青海省', num: null },
                        { name: '宁夏回族自治区', num: null },
                        { name: '新疆维吾尔自治区', num: null }
                    ],
                    tran_rate: [
                        { name: '北京市', num: null },
                        { name: '天津市', num: null },
                        { name: '河北省', num: null },
                        { name: '山西省', num: null },
                        { name: '内蒙古自治区', num: null },
                        { name: '辽宁省', num: null },
                        { name: '吉林省', num: null },
                        { name: '黑龙江省', num: null },
                        { name: '上海市', num: null },
                        { name: '江苏省', num: null },
                        { name: '浙江省', num: null },
                        { name: '福建省', num: null },
                        { name: '安徽省', num: null },
                        { name: '山东省', num: null },
                        { name: '河南省', num: null },
                        { name: '河北省', num: null },
                        { name: '湖南省', num: null },
                        { name: '江西省', num: null },
                        { name: '广东省', num: null },
                        { name: '海南省', num: null },
                        { name: '广西壮族自治区', num: null },
                        { name: '重庆市', num: null },
                        { name: '四川省', num: null },
                        { name: '云南省', num: null },
                        { name: '贵州省', num: null },
                        { name: '西藏自治区', num: null },
                        { name: '陕西省', num: null },
                        { name: '甘肃省', num: null },
                        { name: '青海省', num: null },
                        { name: '宁夏回族自治区', num: null },
                        { name: '新疆维吾尔自治区', num: null }
                    ]
                },
                people_set: {},
                store_data: {
                    store_num: null,
                    day_sum_limited: null,
                    day_sum_limited_wave1: 0,
                    day_sum_limited_wave2: 0,
                    time1_bid: null,
                    time1_bid_wave1: 0,
                    time1_bid_wave2: 0,
                    time2_bid: null,
                    time2_bid_wave1: 0,
                    time2_bid_wave2: 0,
                    time3_bid: null,
                    time3_bid_wave1: 0,
                    time3_bid_wave2: 0,
                    time4_bid: null,
                    time4_bid_wave1: 0,
                    time4_bid_wave2: 0,
                }
            },
            searchAdRules: {
                name: [
                    { required: true, message: '请输入关键词名称', trigger: 'blur' },
                    { min: 1, max: 30, message: '长度在 1 到 30 个字', trigger: 'blur' }
                ],
                category_id: [
                    { required: true, message: '请选择行业分类', trigger: 'change' }
                ],
                gid_id: [
                    { required: true, message: '请选择商品名称', trigger: 'change' }
                ],
                score: [
                    { required: true, message: '请输入分值', trigger: 'blur' },
                    { validator: onlyNumber, trigger: 'blur' }
                ],
                sold_score: [
                    { required: true, message: '请输入分值', trigger: 'blur' },
                    { validator: onlyNumber, trigger: 'blur' }
                ],
                conversion_rate_score: [
                    { required: true, message: '请输入分值', trigger: 'blur' },
                    { validator: onlyNumber, trigger: 'blur' }
                ],
                balance: [
                    { required: true, message: '请输入余额', trigger: 'blur' },
                    { validator: onlyInt, trigger: 'blur' }
                ],
                background: [
                    { required: true, message: '请输入任务背景', trigger: 'blur' },
                    { min: 1, max: 1000, message: '长度在 1 到 1000 个字', trigger: 'blur' }
                ],
                requirement: [
                    { required: true, message: '请输入任务要求', trigger: 'blur' },
                    { min: 1, max: 1000, message: '长度在 1 到 1000 个字', trigger: 'blur' }
                ],                
            },
            //行业类表
            categoryList: [],
            //商品名称列表
            goodNameList: [],
            //官方数据列表(搜索)
            officialDataOptions: [],
            officialDataValue: '',
            //官方数据列表（店铺）
            officialDataOptionsStore: [],
            officialDataValueStore: '',
            timeZone: ['0~6', '6~12', '12~18', '18~24'],
            areaZone: [
                { name: '华北地区', position: 'north' },
                { name: '东北地区', position: 'northeast' },
                { name: '华东地区', position: 'east' },
                { name: '华中地区', position: 'central' },
                { name: '华南地区', position: 'south' },
                { name: '西南地区', position: 'southwest' },
                { name: '西北地区', position: 'northwest' },
            ],
            peopleZone: [
                { name: '核心人群', people: 'kernel' },
                { name: '认知人群', people: 'perceive' },
                { name: '意向人群', people: 'intention' },
                { name: '竞品人群', people: 'products' },
                { name: '店铺人群', people: 'store' },
                { name: '类目相关人群', people: 'category' },
            ],
            //搜索广告id
            searchDId: Number(this.$route.query.id) || 0,
            //跳过设置给的变量
            isType3: false,
            tmpData: [],
            tmpAreaData: [],
            keyWords: [],
            qualityObj: {
                score: null,
                lowValue: null,
                highValue: null
            },
            makeData: false
        }
    },
    mounted() {
        this.getCategoryList();
        this.getGoodNameList();
        this.getOfficialDataList();
        this.getFindSD();
    },
    methods: {
        getKeyWords(val) {
            if (!val) {
                this.keyWords = [];
                return;
            }
            let query = {
                gi_id: val
            }
            this.$http.axiosGetBy(this.$api.ads_keywords, query, (res) => {
                if (res.code === 200) {
                    this.keyWords = res.data.list;
                    this.qualityObj.score = res.data.quality_score;
                    this.qualityObj.highValue = res.data.high_wave;
                    this.qualityObj.lowValue = res.data.low_wave;
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        //编辑搜索广告
        getFindSD() {
            if (this.searchDId) {
                const _this = this;
                this.$http.axiosGetBy(this.$api.findSearchData, { id: this.searchDId }, (res) => {
                    if (res.code === 200) {
                        this.searchAdForm.name = res.data.name;
                        this.searchAdForm.background = res.data.background
                        this.searchAdForm.requirement = res.data.requirement
                        this.searchAdForm.sold_score = res.data.sold_score
                        this.searchAdForm.conversion_rate_score = res.data.conversion_rate_score
                        this.searchAdForm.category_id = Number(res.data.category_id);
                        this.$set(this.searchAdForm, 'gid_id', Number(res.data.gid_id));
                        this.$set(this.searchAdForm, 'score', Number(res.data.score));
                        this.searchAdForm.balance = Number(res.data.balance);
                        this.searchAdForm.remark = res.data.remark;
                        this.getKeyWords(res.data.gid_id);

                        let timeData_obj = {};
                        let total_data = null;
                        if (res.data.search_data.toString() === "[object Object]") {
                            total_data = res.data.search_data;
                        } else {
                            total_data = JSON.parse(res.data.search_data);
                        }
                        _.each(total_data.time_data, (item, index) => {
                            if (/[^]*_wave$/.test(index)) {
                                let new_index1 = index.concat(1);
                                let new_index2 = index.concat(2);
                                if (timeData_obj[new_index1] === undefined) {
                                    timeData_obj[new_index1] = item.split("#")[0];
                                    timeData_obj[new_index2] = item.split("#")[1];
                                }
                            } else {
                                timeData_obj[index] = item;
                            }
                        });
                        this.searchAdForm.time_data = timeData_obj;

                        let areaData_obj = {};
                        _.each(total_data.area_data, (item, index) => {
                            if (/[^]*_wave$/.test(index)) {
                                let new_index1 = index.concat(1);
                                let new_index2 = index.concat(2);
                                if (areaData_obj[new_index1] === undefined) {
                                    areaData_obj[new_index1] = item.split("#")[0];
                                    areaData_obj[new_index2] = item.split("#")[1];
                                }
                            } else {
                                areaData_obj[index] = item;
                            }
                        });
                        this.searchAdForm.area_data = areaData_obj;

                        this.searchAdForm.people_data = total_data.people_data;

                        let storeData_obj = {};
                        _.each(total_data.store_data, (item, index) => {
                            if (/[^]*_wave$/.test(index)) {
                                let new_index1 = index.concat(1);
                                let new_index2 = index.concat(2);
                                if (storeData_obj[new_index1] === undefined) {
                                    storeData_obj[new_index1] = item.split("#")[0];
                                    storeData_obj[new_index2] = item.split("#")[1];
                                }
                            } else {
                                storeData_obj[index] = item;
                            }
                        });
                        this.searchAdForm.store_data = storeData_obj;
                    } else {
                        this.$message({
                            message: res.msg,
                            duration: 1000,
                            type: 'warning',
                            onClose: function() {
                                _this.$router.push('/admin/operatedata/searchads');
                            }
                        });
                    }
                }, (err) => {
                    console.log(err);
                })
            }
        },
        //获取行业分类列表
        getCategoryList() {
            this.$http.axiosGet(this.$api.categoryList, (res) => {
                if (res.code === 200) {
                    this.categoryList = res.data;
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                console.log(err);
            })
        },
        //获取商品名称列表
        getGoodNameList() {
            this.$http.axiosGet(this.$api.goodInfoList, (res) => {
                if (res.code === 200) {
                    this.goodNameList = res.data;
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                console.log(err);
            })
        },
        //获取官方数据
        getOfficialDataList() {
            this.$http.axiosGet(this.$api.searchAdsList, (res) => {
                if (res.code === 200) {
                    this.officialDataOptions = res.data;
                    this.officialDataOptionsStore = res.data;
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                console.log(err);
            })
        },
        //选择的官方数据
        selectFD(val) {
            if (this.isType3) {
                //跳过设置，使用官方数据
                this.$http.axiosGetBy(this.$api.sdUseOfficialData, { id: val, type: '3' }, (res) => {
                    if (res.code === 200) {
                        let time_set_data = JSON.parse(res.data.time_data);
                        let area_data_data = JSON.parse(res.data.area_data);
                        this.searchAdForm.time_set.potential_client = time_set_data.potential_client;
                        this.searchAdForm.time_set.click_rate = time_set_data.click_rate;
                        this.searchAdForm.time_set.tran_rate = time_set_data.tran_rate;
                        this.searchAdForm.people_set = JSON.parse(res.data.people_data);
                        this.searchAdForm.area_set.potential_client = area_data_data.potential_client;
                        this.searchAdForm.area_set.click_rate = area_data_data.click_rate;
                        this.searchAdForm.area_set.tran_rate = area_data_data.tran_rate;
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            }
            //添加搜索数据中使用官方数据
            this.$http.axiosGetBy(this.$api.sdUseOfficialData, { id: val, type: '1' }, (res) => {
                if (res.code === 200) {
                    this.searchAdForm.time_data = res.data.time_data;
                    delete(res.data.time_data.id);
                    delete(res.data.time_data.sa_id);
                    this.searchAdForm.time_data = res.data.time_data;
                    let timeData_obj = {};
                    _.each(this.searchAdForm.time_data, (item, index) => {
                        if (/[^]*_wave$/.test(index)) {
                            let new_index1 = index.concat(1);
                            let new_index2 = index.concat(2);
                            if (timeData_obj[new_index1] === undefined) {
                                timeData_obj[new_index1] = item.split("#")[0];
                                timeData_obj[new_index2] = item.split("#")[1];
                            }
                        } else {
                            timeData_obj[index] = item;
                        }
                    });
                    this.searchAdForm.time_data = timeData_obj;

                    delete(res.data.area_data.id);
                    delete(res.data.area_data.sa_id);
                    this.searchAdForm.area_data = res.data.area_data;
                    let areaData_obj = {};
                    _.each(this.searchAdForm.area_data, (item, index) => {
                        if (/[^]*_wave$/.test(index)) {
                            let new_index1 = index.concat(1);
                            let new_index2 = index.concat(2);
                            if (areaData_obj[new_index1] === undefined) {
                                areaData_obj[new_index1] = item.split("#")[0];
                                areaData_obj[new_index2] = item.split("#")[1];
                            }
                        } else {
                            areaData_obj[index] = item;
                        }
                    });
                    this.searchAdForm.area_data = areaData_obj;

                    this.searchAdForm.people_data = res.data.people_data;
                    delete(res.data.people_data.id);
                    delete(res.data.people_data.sa_id);
                    this.searchAdForm.people_data = res.data.people_data;
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                console.log(err);
            })
        },
        selectFDStore(val) {
            //添加竞争店铺中使用官方数据
            this.$http.axiosGetBy(this.$api.sdUseOfficialData, { id: val, type: '2' }, (res) => {
                if (res.code === 200) {
                    this.searchAdForm.store_data = res.data;
                    delete(res.data.id);
                    delete(res.data.sa_id);
                    this.searchAdForm.store_data = res.data;
                    let storeData_obj = {};
                    _.each(this.searchAdForm.store_data, (item, index) => {
                        if (/[^]*_wave$/.test(index)) {
                            let new_index1 = index.concat(1);
                            let new_index2 = index.concat(2);
                            if (storeData_obj[new_index1] === undefined) {
                                storeData_obj[new_index1] = item.split("#")[0];
                                storeData_obj[new_index2] = item.split("#")[1];
                            }
                        } else {
                            storeData_obj[index] = item;
                        }
                    });
                    this.searchAdForm.store_data = storeData_obj;
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                console.log(err);
            })
        },
        //生成数据
        generateData(formName) {
            this.$refs[formName].validate( async (valid) => {
                if (valid) {
                    let formData = new FormData();
                    formData.append('gid_id', this.searchAdForm.gid_id);
                    let timeData = this.searchAdForm.time_data;
                    let areaData = this.searchAdForm.area_data;

                    //验证时段设置
                    let time_tmpObj = {};
                    let dialog_flag = {
                        time_data: 0,
                        area_data: 0,
                        people_data: 0
                    }
                    _.each(timeData, (item, index) => {
                        if (/[^]*_wave\d{1}$/.test(index)) {
                            let new_index = index.substr(0, index.length - 1);
                            if (time_tmpObj[new_index] === undefined) {
                                time_tmpObj[new_index] = item;
                            } else {
                                if (time_tmpObj[new_index] >= item) {
                                    dialog_flag.time_data++;
                                    if (dialog_flag.time_data === 1) {
                                        this.$message.error('时段设置波动范围的开始范围必须小于结束范围');
                                    }
                                    this.$refs[`${index}`][0].$el.className += ' input-warning';
                                } else {
                                    this.$refs[`${index}`][0].$el.className = this.$refs[`${index}`][0].$el.className.replace(/input-warning/g, '');
                                }
                            }

                        }
                    });
                    let area_tmpObj = {};
                    _.each(areaData, (item, index) => {
                        if (/[^]*_wave\d{1}$/.test(index)) {
                            let new_index = index.substr(0, index.length - 1);
                            if (area_tmpObj[new_index] === undefined) {
                                area_tmpObj[new_index] = item;
                            } else {
                                if (area_tmpObj[new_index] >= item) {
                                    dialog_flag.area_data++;
                                    if (dialog_flag.area_data === 1) {
                                        this.$message({
                                            message: '地区设置波动范围的开始范围必须小于结束范围',
                                            offset: 60,
                                            type: 'error'
                                        });
                                    }
                                    this.$refs[`${index}`][0].$el.className += ' input-warning';
                                } else {
                                    this.$refs[`${index}`][0].$el.className = this.$refs[`${index}`][0].$el.className.replace(/input-warning/g, '');
                                }
                            }

                        }
                    });

                    //合并波动范围
                    let timeData_obj = {};
                    _.each(timeData, (item, index) => {
                        if (/[^]*_wave\d{1}$/.test(index)) {
                            let new_index = index.substr(0, index.length - 1);
                            if (timeData_obj[new_index] === undefined) {
                                timeData_obj[new_index] = item;
                            } else {
                                timeData_obj[new_index] += `#${item}`;
                            }
                        } else {
                            timeData_obj[index] = item;
                        }
                    });
                    let areaData_obj = {};
                    _.each(areaData, (item, index) => {
                        if (/[^]*_wave\d{1}$/.test(index)) {
                            let new_index = index.substr(0, index.length - 1);
                            if (areaData_obj[new_index] === undefined) {
                                areaData_obj[new_index] = item;
                            } else {
                                areaData_obj[new_index] += `#${item}`;
                            }
                        } else {
                            areaData_obj[index] = item;
                        }
                    });
                    let peopleData = this.searchAdForm.people_data;
                    //验证人群定向的潜在客户数总和必须为100
                    if (this.isSetDataOne) {
                        let total_num = 0;
                        _.each(peopleData, (item, index) => {
                            if (/[^]_potential_client$/.test(index)) {
                                total_num += parseFloat(item);
                            }
                        });
                        if (Number(total_num) !== 100) {
                            this.$message({
                                type: 'warning',
                                message: '人群定向的潜在客户数总和必须为100！',
                                offset: 100
                            });
                            return false;
                        }
                    }
                    if (dialog_flag.time_data) {
                        return;
                    }
                    if (dialog_flag.area_data) {
                        return;
                    }

                    formData.append('time_data', JSON.stringify(timeData_obj));
                    formData.append('area_data', JSON.stringify(areaData_obj));
                    formData.append('people_data', JSON.stringify(peopleData));
                    try {
                        const res = await generateSearchAdsData(formData);
                        if (res.code === 200) {
                            this.isSetDataOne = false
                            this.isSetDataTwo = true
                            this.searchAdForm.time_set = res.data.time_set;
                            this.searchAdForm.area_set = res.data.area_set;
                            this.searchAdForm.people_set = res.data.people_set;
                            this.initTimezone(res.data.time_set);
                            this.initAreazone(res.data.area_set);
                        } else {
                            this.$message.warning(res.msg)
                        }
                    } catch (e) {
                        this.$message.warning("生成数据异常")
                        console.log("e", e)
                    }
                    
                    // this.$http.axiosPost(this.$api.generateData, formData, (res) => {
                    //     if (res.code === 200) {
                    //         this.isSetDataOne = false;
                    //         this.isSetDataTwo = true;
                    //         this.searchAdForm.time_set = res.data.time_set;
                    //         this.searchAdForm.area_set = res.data.area_set;
                    //         this.searchAdForm.people_set = res.data.people_set;
                    //         this.initTimezone(res.data.time_set);
                    //         this.initAreazone(res.data.area_set);
                    //     } else {
                    //         this.$message.warning(res.msg)
                    //     }
                    // }, (err) => {
                    //     console.log(err);
                    // })
                } else {
                    return false;
                }
            });
        },
        initTimezone(time_zone = {}) {
            if (!time_zone.click_rate) {
                time_zone.click_rate = [];
                time_zone.tran_rate = [];
                time_zone.potential_client = [];
                for (let i = 0; i < 24; i++) {
                    time_zone.click_rate.push(0);
                    time_zone.tran_rate.push(0);
                    time_zone.potential_client.push(0);
                }
            }
            let tmpArr = [];
            _.each(time_zone.click_rate, (item, index) => {
                if ([0, 6, 12, 18].indexOf(index) !== -1) {
                    if ([6, 12, 18].indexOf(index) !== -1) {
                        this.tmpData.push(tmpArr);
                    }
                    tmpArr = [];
                }
                let tmpItem = {
                    label: `${index}-${index+1}`,
                    click_rate: item,
                    tran_rate: time_zone.tran_rate[index],
                    potential_client: time_zone.potential_client[index]
                }
                tmpArr.push(tmpItem);
                if (index === 23) {
                    this.tmpData.push(tmpArr);
                }
            });
        },
        initAreazone(area_zone = {}) {
            let province = '北京市#天津市#河北省#山西省#内蒙古自治区#辽宁省#吉林省#黑龙江省#上海市#江苏省#浙江省#福建省#安徽省#山东省#河南省#河北省#湖南省#江西省#广东省#海南省#广西壮族自治区#重庆市#四川省#云南省#贵州省#西藏自治区#陕西省#甘肃省#青海省#宁夏回族自治区#新疆维吾尔自治区';
            if (!area_zone.click_rate) {
                area_zone.click_rate = [];
                area_zone.tran_rate = [];
                area_zone.potential_client = [];
                province = province.split('#');
                for (let i = 0; i < province.length; i++) {
                    let tmp = {
                        name: province[i],
                        num: 0
                    };
                    area_zone.click_rate.push(tmp);
                    area_zone.tran_rate.push(tmp);
                    area_zone.potential_client.push(tmp);
                }
            }
            let tmpArr = [];
            _.each(area_zone.click_rate, (item, index) => {
                if ([0, 6, 12, 18, 24, 30].indexOf(index) !== -1) {
                    if ([6, 12, 18, 24, 30].indexOf(index) !== -1) {
                        this.tmpAreaData.push(tmpArr);
                    }
                    tmpArr = [];
                }
                let tmpItem = {
                    label: item.name,
                    click_rate: item.num,
                    tran_rate: area_zone.tran_rate[index].num,
                    potential_client: area_zone.potential_client[index].num
                }
                tmpArr.push(tmpItem);
                if (index === 30) {
                    this.tmpAreaData.push(tmpArr);
                }
            });
        },
        // 上一步按钮
        prevBtn(index) {
            console.log(index)
            if (index === 1) {
                this.$router.go(-1);
            } else if (index === 2) {
                this.isSetDataOne = false
                this.stepActive = 1;
            } else if (index === 3) {
                this.stepActive = 2;
                this.isSetDataOne = true;
            } else if (index === 4) {
                this.$router.push('/admin/operatedata/searchads');
            }
        },
        prevBtnSetDataTwo() {
            this.isSetDataTwo = false;
            this.isSetDataOne = true;
        },
        //跳过
        jumpNext() {
            this.isSetDataTwo = true;
            this.isSetDataOne = false;
            this.isType3 = true;
            this.tmpData = [];
            this.tmpAreaData = [];
            this.initTimezone();
            this.initAreazone();
        },
        // 下一步按钮
        async nextBtn(index, formName) {
            // 第一步的下一步
            if (index === 1) {
                if (!this.searchAdForm.category_id) return this.$message.error("请选择行业分类！");
                if (!this.searchAdForm.gid_id) return this.$message.error("请选择商品名称！");
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const total = this.searchAdForm.sold_score + this.searchAdForm.score + this.searchAdForm.conversion_rate_score;
                        if (total !== 100) {
                            this.$message.warning("分值总和必须为100")
                            return
                        }
                        this.stepActive = 2;
                        this.isSetDataOne = true;
                    } else {
                        return false;
                    }
                });
                // 第二步的下一步
            } else if (index === 2) {
                let peopleData = this.searchAdForm.people_set;
                let potential_client_tmp = '';
                let tran_rate_tmp = '';
                let click_rate_tmp = '';
                _.each(peopleData, (item, index) => {
                    if (/[^]_potential_client$/.test(index)) {
                        potential_client_tmp = item;
                    }
                });
                _.each(peopleData, (item, index) => {
                    if (/[^]_click_rate$/.test(index)) {
                        click_rate_tmp = item;
                    }
                });
                _.each(peopleData, (item, index) => {
                    if (/[^]_tran_rate$/.test(index)) {
                        tran_rate_tmp = item;
                    }
                });
                if (potential_client_tmp === undefined) {
                    return this.$message.error('请输入人群定向--潜在客户的每一项');
                }
                if (click_rate_tmp === undefined) {
                    return this.$message.error('请输入人群定向--点击率的每一项');
                }
                if (tran_rate_tmp === undefined) {
                    return this.$message.error('请输入人群定向--转化率的每一项');
                }
                this.stepActive = 3;
                this.isSetDataTwo = false;

            } else if (index === 3) {
                this.makeData = true;
                let formData = {};
                formData.name = this.searchAdForm.name;
                formData.category_id = this.searchAdForm.category_id;
                formData.gid_id = this.searchAdForm.gid_id;
                formData.score = Number(this.searchAdForm.score);
                formData.balance = Number(this.searchAdForm.balance);
                if (this.searchAdForm.remark) {
                    formData.remark = this.searchAdForm.remark;
                }
                formData.background = this.searchAdForm.background
                formData.requirement = this.searchAdForm.requirement

                formData.sold_score = Number(this.searchAdForm.sold_score)
                formData.conversion_rate_score = Number(this.searchAdForm.conversion_rate_score)

                let timeSet = {};
                timeSet.potential_client = this.searchAdForm.time_set.potential_client.map(item => item.toString());
                timeSet.click_rate = this.searchAdForm.time_set.click_rate.map(item => item.toString());
                timeSet.tran_rate = this.searchAdForm.time_set.tran_rate.map(item => item.toString());
                formData.time_data = JSON.stringify(timeSet);

                let areaSet = this.searchAdForm.area_set;
                formData.area_data = JSON.stringify(areaSet);

                formData.people_data = JSON.stringify(this.searchAdForm.people_set);

                let storeData = this.searchAdForm.store_data;

                let storeData_obj = {};
                _.each(storeData, (item, index) => {
                    if (/[^]*_wave\d{1}$/.test(index)) {
                        let new_index = index.substr(0, index.length - 1);
                        if (storeData_obj[new_index] === undefined) {
                            storeData_obj[new_index] = item;
                        } else {
                            storeData_obj[new_index] += `#${item}`;
                        }
                    } else {
                        storeData_obj[index] = item;
                    }
                });
                formData.store_data = JSON.stringify(storeData_obj);

                //验证竞争店铺的波动范围
                if (storeData.day_sum_limited_wave1 >= storeData.day_sum_limited_wave2) {
                    this.$refs.day_sum_limited_wave2.$el.className += ' input-warning';
                    return this.$message.error('波动范围的开始范围必须小于结束范围');
                } else {
                    this.$refs.day_sum_limited_wave2.$el.className = this.$refs.day_sum_limited_wave2.$el.className.replace(/input-warning/g, '');
                }
                if (storeData.time1_bid_wave1 >= storeData.time1_bid_wave2) {
                    this.$refs.time1_bid_wave2.$el.className += ' input-warning';
                    return this.$message.error('波动范围的开始范围必须小于结束范围');
                } else {
                    this.$refs.time1_bid_wave2.$el.className = this.$refs.time1_bid_wave2.$el.className.replace(/input-warning/g, '');
                }
                if (storeData.time2_bid_wave1 >= storeData.time2_bid_wave2) {
                    this.$refs.time2_bid_wave2.$el.className += ' input-warning';
                    return this.$message.error('波动范围的开始范围必须小于结束范围');
                } else {
                    this.$refs.time2_bid_wave2.$el.className = this.$refs.time2_bid_wave2.$el.className.replace(/input-warning/g, '');
                }
                if (storeData.time3_bid_wave1 >= storeData.time3_bid_wave2) {
                    this.$refs.time3_bid_wave2.$el.className += ' input-warning';
                    return this.$message.error('波动范围的开始范围必须小于结束范围');
                } else {
                    this.$refs.time3_bid_wave2.$el.className = this.$refs.time3_bid_wave2.$el.className.replace(/input-warning/g, '');
                }
                if (storeData.time4_bid_wave1 >= storeData.time4_bid_wave2) {
                    this.$refs.time4_bid_wave2.$el.className += ' input-warning';
                    return this.$message.error('波动范围的开始范围必须小于结束范围');
                } else {
                    this.$refs.time4_bid_wave2.$el.className = this.$refs.time4_bid_wave2.$el.className.replace(/input-warning/g, '');
                }

                let searchData = {};
                searchData.people_data = this.searchAdForm.people_data;
                let timeData_obj = {};
                _.each(this.searchAdForm.time_data, (item, index) => {
                    if (/[^]*_wave\d{1}$/.test(index)) {
                        let new_index = index.substr(0, index.length - 1);
                        if (timeData_obj[new_index] === undefined) {
                            timeData_obj[new_index] = item;
                        } else {
                            timeData_obj[new_index] += `#${item}`;
                        }
                    } else {
                        timeData_obj[index] = item;
                    }
                });
                searchData.time_data = timeData_obj;
                let areaData_obj = {};
                _.each(this.searchAdForm.area_data, (item, index) => {
                    if (/[^]*_wave\d{1}$/.test(index)) {
                        let new_index = index.substr(0, index.length - 1);
                        if (areaData_obj[new_index] === undefined) {
                            areaData_obj[new_index] = item;
                        } else {
                            areaData_obj[new_index] += `#${item}`;
                        }
                    } else {
                        areaData_obj[index] = item;
                    }
                });
                searchData.area_data = areaData_obj;
                searchData.store_data = storeData_obj;
                // formData.append('search_data', JSON.stringify(searchData));
                formData.search_data = JSON.stringify(searchData);
                //获取店铺的日预算
                let daily_bugget = [];
                for (let i = 0; i < storeData_obj.store_num; i++) {
                    let bugget = this.$common.getOffer(storeData_obj.day_sum_limited, storeData_obj.day_sum_limited_wave);
                    daily_bugget.push(bugget);
                }

                let arr = [];
                _.each(this.keyWords, (item) => {
                    let offer_rank = this.$common.calculateStoreData(storeData_obj);
                    let avg_store_offer = this.$common.getAvgStoreOffer(offer_rank, storeData_obj.store_num);
                    let com_index = this.$common.getOffer(this.qualityObj.score, `${this.qualityObj.lowValue}#${this.qualityObj.highValue}`);
                    let tmp = {
                        id: item.id,
                        keyword: item.keyword,
                        offer_rank,
                        avg_store_offer,
                        search_num: item.search_num,
                        com_index: Math.floor(com_index)
                    }
                    arr.push(tmp);
                });
                formData.daily_budget = JSON.stringify(daily_bugget);
                // formData.store_data_random = JSON.stringify(arr);
                // console.log("arr", arr)
                // console.log("formData", formData)
                // return
                if (this.searchDId) {
                    // formData.append('id', this.searchDId);
                    formData.id = this.searchDId;
                }
                const res = await saveSearchAdsData(formData)
                if (res.code === 200) {
                    this.batchSaveData(arr, res.data.id)
                }
            }
        },
        async batchSaveData(arr, id) {
            const formdata = {};
            console.log("arr", arr)
            if (arr.length < 100) {
                formdata.id = id
                formdata.list = arr
                const res = await batchSaveAdsData(formdata)
                try {
                    const res = await batchSaveAdsData(formdata)
                    const flag = i + 1
                    if (res.code === 200 && flag === size) {
                        this.createMessage = this.searchDId ? "编辑成功" : "创建成功";
                        this.stepActive = 4;
                    } else {
                        this.$message.error(res.msg)
                        return
                    }
                } catch (e) {
                    console.log("error", e)
                    return
                }
            } else {
                const chunk = 20
                const size = Math.ceil(arr.length / chunk)
                this.total = size;
                for (let i = 0; i < size; i++) {
                    console.log("i", i)
                    const start = i * chunk;
                    const end =  Math.min(start + chunk, arr.length);
                    const list = arr.slice(start, end)
                    formdata.id = id;
                    formdata.list = list
                    try {
                        const res = await batchSaveAdsData(formdata)
                        if (res.code === 200) {
                            const flag = i + 1
                            this.process_index = flag
                            if (flag === size) {
                                this.createMessage = this.searchDId ? "编辑成功" : "创建成功";
                                this.stepActive = 4;
                            }
                        } else {
                            this.$message.error(res.msg)
                            return
                        }
                    } catch (e) {
                        console.log("error", e)
                        return
                    }
                }
            }






        }
    }
}